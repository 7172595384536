<template>
    <div class="research-detail-page" :class="pageClass">
        <ClientOnly>
            <ChaptersNav
                v-if="chapters?.length"
                class="chapters-nav"
                :title="title"
                :chapters="chapters"
                ref="chaptersNavElement"
            >
            </ChaptersNav>
        </ClientOnly>

        <Publication :tags="combinedTags" :programs="affiliationContent">
            <template #hero>
                <div v-if="isResearchMaterial" class="hero">
                    <div class="hero-inner">
                        <div class="hero-left">
                            <ImageBlock
                                v-if="publicationDetails?.cover"
                                :image="publicationDetails?.cover"
                                :noCaption="true"
                            />
                        </div>

                        <div class="hero-right">
                            <PublicationSummary
                                :attributions="attributions"
                                :subtype="subtype === 'other' ? 'research' : subtype"
                                :subtypeLabel="subtype === 'other' ? eyebrow : undefined"
                                :dek="dek"
                                :title="title"
                                class="material"
                                :contributors="mainAuthors"
                                :pdfResources="combinedPDFs"
                                :primaryLink="primaryAssetLink"
                                :publisherName="publisherName"
                                :publishedAt="publishedAt"
                                :translations="translations"
                                :pageLanguage="pageLanguage"
                            />
                        </div>
                    </div>
                </div>

                <ImageHero v-else-if="hero?.image" v-bind="hero" :noCaption="false" :pageLanguage="pageLanguage" />
            </template>

            <template #head>
                <PublicationSummary
                    v-if="!isResearchMaterial"
                    :attributions="attributions"
                    :subtype="subtype === 'other' ? 'research' : subtype"
                    :subtypeLabel="subtype === 'other' ? eyebrow : undefined"
                    :dek="dek"
                    :title="title"
                    :primaryLink="primaryAssetLink"
                    :contributors="mainAuthors"
                    :pdfResources="combinedPDFs"
                    :translations="translations"
                    :publisherName="publisherName"
                    :publishedAt="publishedAt"
                    :pageLanguage="pageLanguage"
                />
            </template>

            <template #main-left v-if="streamContained?.length">
                <AsideBlock
                    v-if="chapters?.length"
                    :heading="
                        $t('table of contents', 1, {
                            locale: pageLanguage,
                        })
                    "
                    class="table-of-contents"
                >
                    <ChaptersList :chapters="chapters" ref="chaptersListElement" />
                </AsideBlock>

                <AsideBlock
                    v-if="additionalLinks?.length"
                    :heading="
                        $t('additional links', 1, {
                            locale: pageLanguage,
                        })
                    "
                >
                    <LinkList :links="additionalLinks" class="supplemental-links" />
                </AsideBlock>

                <AsideBlock :hasPadding="false">
                    <AffiliationCard
                        v-for="item in affiliationContent"
                        :key="item.reference.path"
                        :heading="item.reference.title"
                        :dek="item.reference.dek || item.reference.about"
                        headingElement="h3"
                        :image="item.image"
                        :mobileImage="item.mobileImage"
                        :logoImage="item.logoImage"
                        :url="item.reference.path"
                        :eyebrow="$t(item.eyebrow)"
                        :pageLanguage="pageLanguage"
                        class="card"
                        :isDCPrimary="isDCPrimary"
                    />
                </AsideBlock>
            </template>

            <template #main-center v-if="streamContained?.length">
                <PublicationStreamBlocks v-if="streamContained?.length" :blocks="streamContained" class="stream" />

                <!-- this seems to be duplicated below so all chapters are there twice ... commenting out in case we need it-->
                <!-- <div v-if="chapters?.length" class="chapter-sections" ref="chapterSectionsElement">
                    <section
                        v-for="chapter in chapters"
                        :id="`${titleToSlug(chapter.label)}`"
                        :key="chapter.id"
                        ref="chapterElements"
                        class="chapter-section"
                    >
                        <PublicationStreamBlocks
                            v-if="chapter.streamContained?.length"
                            :blocks="chapter.streamContained"
                            :heading="chapter.label"
                        />
                    </section>
                </div> -->
            </template>

            <template #main-right v-if="streamContained?.length">
                <template v-if="mainAuthors?.length">
                    <AsideBlock
                        v-if="mainAuthors?.length > 1"
                        :heading="
                            $t('authors', 1, {
                                locale: pageLanguage,
                            })
                        "
                    >
                        <ul class="authors">
                            <li v-for="person in mainAuthors" :key="person.id" class="author">
                                <PersonListItem
                                    :pageLanguage="pageLanguage"
                                    :person="person"
                                    :isInline="true"
                                    size="33"
                                />
                            </li>
                        </ul>
                    </AsideBlock>
                    <PersonListItem
                        v-else-if="
                            mainAuthors?.length === 1 &&
                            (mainAuthors?.[0].dek?.root.children[0].children.length > 0 ||
                                mainAuthors?.[0].personTitle !== null)
                        "
                        :pageLanguage="pageLanguage"
                        :person="mainAuthors?.[0]"
                        size="33"
                    />
                </template>
            </template>
            <template #chapters>
                <div v-if="chapters?.length" class="chapter-sections" ref="chapterSectionsElement">
                    <MainWithAsidesTier v-for="(chapter, index) in chapters" :key="chapter.id">
                        <!-- if we start with a chapter, we need to move the main left into the chapter section-->
                        <template #main-left v-if="!streamContained?.length && index === 0">
                            <AsideBlock
                                v-if="chapters?.length"
                                :heading="
                                    $t('table of contents', 1, {
                                        locale: pageLanguage,
                                    })
                                "
                                class="table-of-contents"
                            >
                                <ChaptersList :chapters="chapters" ref="chaptersListElement" />
                            </AsideBlock>

                            <AsideBlock
                                v-if="additionalLinks?.length"
                                :heading="
                                    $t('additional links', 1, {
                                        locale: pageLanguage,
                                    })
                                "
                            >
                                <LinkList :links="additionalLinks" class="supplemental-links" />
                            </AsideBlock>

                            <AsideBlock :hasPadding="false">
                                <AffiliationCard
                                    v-for="item in affiliationContent"
                                    :key="item.reference.path"
                                    :heading="item.reference.title"
                                    :dek="item.reference.dek || item.reference.about"
                                    headingElement="h3"
                                    :image="item.image"
                                    :mobileImage="item.mobileImage"
                                    :logoImage="item.logoImage"
                                    :url="item.reference.path"
                                    :eyebrow="$t(item.eyebrow)"
                                    :pageLanguage="pageLanguage"
                                    class="card"
                                    :isDCPrimary="isDCPrimary"
                                />
                            </AsideBlock>
                        </template>
                        <template #main-center>
                            <section
                                :id="`${titleToSlug(chapter.label)}`"
                                ref="chapterElements"
                                class="chapter-section" 
                                v-if="pageLanguage === 'en'"
                            >
                                <PublicationStreamBlocks
                                    v-if="chapter.streamContained?.length"
                                    :blocks="chapter.streamContained"
                                    :heading="chapter.label"
                                    :index="index"
                                />
                            </section>
                            <section
                                :id="`chapter-${getChapterNumber(index)}`"
                                ref="chapterElements"
                                class="chapter-section" 
                                v-else
                            >
                                <PublicationStreamBlocks
                                    v-if="chapter.streamContained?.length"
                                    :blocks="chapter.streamContained"
                                    :heading="chapter.label"
                                    :index="index"
                                />
                            </section>
                        </template>
                        <template #main-right>
                            <AsideBlock
                                v-if="chapter.authors?.length > 1"
                                :heading="
                                    $t('authors', 1, {
                                        locale: pageLanguage,
                                    })
                                "
                            >
                                <ul class="authors">
                                    <li v-for="person in chapter.authors" :key="person.id" class="author">
                                        <PersonListItem
                                            :pageLanguage="pageLanguage"
                                            :person="person"
                                            :isInline="true"
                                            size="33"
                                        />
                                    </li>
                                </ul>
                            </AsideBlock>
                            <PersonListItem
                                v-else-if="chapter.authors?.length"
                                :pageLanguage="pageLanguage"
                                :person="chapter.authors?.[0]"
                                size="33"
                            />
                        </template>
                    </MainWithAsidesTier>
                </div>
            </template>

            <template #footer>
                <RelatedContentTier
                    v-if="relatedContent?.length"
                    :attributionTitle="relatedAttributionLink"
                    :content="relatedContent"
                    :pageLanguage="pageLanguage"
                    :pieceTitle="title"
                >
                </RelatedContentTier>
            </template>
        </Publication>
    </div>
</template>

<script setup>
const { getCenterTagsBySyndication, currentCenter } = useCenters();
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    title: {
        type: String,
        default: '',
        required: true,
    },
    contributors: {
        type: Array,
        default: () => [],
    },
    mainAuthors: {
        type: Array,
        default: () => [],
    },
    hero: {
        type: Object,
        default: null,
    },
    dek: {
        type: Object,
        default: () => {},
    },
    eyebrow: {
        type: String,
        default: '',
    },
    subtype: {
        type: String,
        default: '',
    },
    translations: {
        type: Object,
        default: () => {},
    },
    pdfResources: {
        type: Array,
        default: () => [],
    },
    streamContained: {
        type: Array,
        default: () => [],
    },
    syndications: {
        type: Object,
    },
    publishedAt: {
        type: String,
        default: '',
    },
    publisherName: {
        type: String,
        default: '',
    },
    publicationDetails: {
        type: Object,
    },
    primaryAssetLink: {
        type: Object,
    },
    supplementalLinks: {
        type: Array,
        default: () => [],
    },
    relatedContent: {
        type: Array,
        default: () => [],
    },
    tags: {
        type: Array,
    },
    locale: {
        type: String,
        default: '',
    },
});
const getChapterNumber = (index) => {
    return index < 10 ? `0${index}` : index;
};
let jsonImg = '';
if (props.hero?.image?.url) {
    jsonImg = props.hero.image.url;
}
let canonicalURL = 'https://carnegieendowment.org' + props.path;
if (props.locale) {
    canonicalURL = canonicalURL + '?lang=' + props.locale;
}

let jsonAuthor = '';
if (props.mainAuthors?.length) {
    for (const [index, person] of props.mainAuthors.entries()) {
        jsonAuthor = jsonAuthor + '{"@type": "Person","name": "' + person.title + '"}';
        if (index < props.mainAuthors.length - 1) {
            jsonAuthor = jsonAuthor + ',';
        }
    }
}

const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: props.title,
    image: jsonImg,
    url: canonicalURL,
    author: jsonAuthor,
};
useHead({
    script: [
        {
            hid: 'breadcrumbs-json-ld',
            type: 'application/ld+json',
            innerHTML: JSON.stringify(jsonld),
        },
    ],
    link: [
        {
            rel: 'canonical',
            href: canonicalURL,
        },
    ],
});

const pageClass = computed(() => {
    return pageLanguage === 'ar' ? 'direction-rtl' : '';
});

const combinedPDFs = computed(() => {
    if (props.primaryAssetLink?.file?.url) {
        const supplementalDownloads = props.supplementalLinks.filter((item) => item.link.type === 'download');
        return [props.primaryAssetLink, ...props.pdfResources, ...supplementalDownloads.map((link) => link.link)];
    } else if (props.supplementalLinks.length || props.pdfResources.length) {
        const supplementalDownloads = props.supplementalLinks.filter((item) => item.link.type === 'download');
        return [...props.pdfResources, ...supplementalDownloads.map((link) => link.link)];
    } else {
        return [];
    }
});

const contributorsDeduped = computed(() => {
    return [...new Map(props.contributors.map((item) => [item.title, item])).values()];
});

const isResearchMaterial = computed(() => {
    return (
        props.subtype === 'book' ||
        props.subtype === 'report' ||
        (props.subtype === 'other' && props.publicationDetails?.cover)
    );
});

const additionalLinks = computed(() => {
    if ((props.primaryAssetLink?.file?.url || props.primaryAssetLink?.url) && props.supplementalLinks.length) {
        return [props.primaryAssetLink, ...props.supplementalLinks.map((link) => link.link)];
    } else if (props.primaryAssetLink?.file?.url || props.primaryAssetLink?.url) {
        return [props.primaryAssetLink];
    } else if (props.supplementalLinks.length) {
        return [...props.supplementalLinks.map((link) => link.link)];
    } else {
        return [];
    }
});

const attributions = useAttribution(props.syndications, props.parent);

const relatedAttributionLink = computed(() => {
    if (currentCenter.value.title === "Carnegie Endowment for International Peace") {return 'Carnegie'}
    else {return currentCenter.value.title}
})

let isDCPrimary = true;
if (attributions.attributionItems.length) {
    isDCPrimary = false;
}

const affiliationContent = getAffiliationContent(props.syndications);
const collectionContent = getCollectionContent(props.syndications);

const chapterElements = ref(null);
const chapters = ref([...(props.publicationDetails?.chapters || [])]);
const chaptersListElement = ref(null);
const chapterSectionsElement = ref(null);
const chaptersNavElement = ref(null);

const combinedTags = getCenterTagsBySyndication(props.syndications, props.tags);

const GTMType = props.subtype;
const { GTMCenter, GTMAuthors, GTMPrograms, GTMProjects, GTMRegions, GTMTopics } = useGTMContent(
    'syndication',
    props,
    affiliationContent,
    combinedTags,
    collectionContent,
);
const GTMLang = props.locale;

onMounted(() => {
    const { y } = useScroll(document);
    const { top } = useElementBounding(chapterSectionsElement);
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: GTMType,
        Expert: GTMAuthors,
        'Taxonomy Tags Program': GTMPrograms,
        'Taxonomy Special Project': GTMProjects,
        'Taxonomy Tags Regions': GTMRegions,
        'Taxonomy Tags Topics': GTMTopics,
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });

    const allChapterElements = chapterElements.value;
    chapters.value = chapters.value?.map((chapter, index) => {
        const isInView = ref(false);
        useIntersectionObserver(
            allChapterElements[index],
            ([{ isIntersecting }]) => {
                isInView.value = y.value < top.value && index === 0 ? true : isIntersecting;
            },
            { rootMargin: '-50% 0% -50% 0%', threshold: 0 },
        );
        return {
            isInView,
            ...chapter,
        };
    });
});

const headerAttributesStore = useHeaderAttributesStore();
onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'grey' });
});
</script>

<style lang="scss" scoped>
.research-detail-page {
    position: relative;
}

.hero {
    background-color: color(grey, default, 0.6);
}

.hero-inner {
    align-items: center;
    padding-bottom: 5rem;
    padding-top: 5rem;
    @include content-section;
    @include grid;

    @include media-query(mobile) {
        padding-left: 0;
        padding-right: 0;
    }
}

.hero-left {
    grid-column: 1 / span 5;
    max-height: 90%;
    @include content-area;

    @include media-query(mobile) {
        padding-left: 0;
        padding-right: 0;
        grid-column: 3 / span 7;
        margin-bottom: 2.5rem;
    }
    @include media-query(phone) {
        grid-column: 1 / span 5;
    }
}

.hero-right {
    grid-column: 6 / span 7;
    padding-left: 8rem;
    padding-right: 8rem;

    @include media-query(tablet) {
        @include content-area;
    }

    @include media-query(mobile) {
        grid-column: 1 / -1;
        padding: 0;
        @include content-padding;
    }
}

.table-of-contents {
    @include media-query(mobile) {
        display: none;
    }
}

.chapter-sections {
    display: flex;
    flex-direction: column;
    gap: 4.6rem;
    margin-bottom: vertical-space(3);
    margin-top: vertical-space(3);
}

.chapter-section {
    @include z-index(below-arbitrary);
    padding-top: 11rem;
    margin-top: -11rem;

    @include media-query(phone) {
        padding-top: 4rem;
        margin-top: -4rem;
    }
}

.stream {
    padding-bottom: vertical-space(4);
    padding-top: vertical-space(4);
    border-top: 1px solid palette(divider);
}

.authors {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
</style>
